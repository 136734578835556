import React, { useState} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import GrayDownloadIconButton from "../../UI/GrayDownloadIconButton";
import OurResearchMobile from "../OurResearchMobile";
import { useIsMobile } from "../../../utils/hooks";

const InvestigationPathways = () =>
{
  const [ modalName, setModalName ] = useState("");
  const isMobile = useIsMobile();

  if (isMobile) {
    return <OurResearchMobile />;
  }
  return (
    <div className={"hematology-our-research-wrapper" + (!!modalName ? " blurry-background" : "")}  style={{ backgroundImage: "url(/img/hematology/research/background-investigational-pathways-q4-2021.png)" }}>
      <div className="hematology-our-research-header">
        <Link to="/" className="logo"><img src={ process.env.PUBLIC_URL + "/img/logo.png" } alt="BMS logo" /></Link>
      </div>

      <section className="main-area">
        <div className="main-area-wrapper">
          <div className="main-area-wrapper-inner investigation-pathways-main-area-wrapper">
            <h1>Our Investigational Pathways</h1>
            <a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/HE-US-2100801.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">
            <div className="targeting-bcma-in-mm link-wrapper" >
              <h3>Cellular Therapies</h3>
            </div>
            </a>
            <a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/HE-US-2100802.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">
            <div className="cereblon-modulation link-wrapper" >

              <h3>CELMoD Agents</h3>
            </div>
            </a>
            <div className="lymphoma-targets link-wrapper" onClick={ () => setModalName("investigational-targets") } >
              <h3>Investigational Targets</h3>
            </div>

            <div className="investigation-pathways-explore-other-trials-wrapper">
            <a href="https://www.bmsscience.com/pipeline-trials/?s=&filter_location=&filter_phase=&filter_status=&filter_specialty=.hematology&search_type=&orderby=relevance&is_parent_ta=YES&filter_celgene_da=" target="_blank" rel="noreferrer" className="link-text">
              <span>Explore<br /> Other Trials <br /> <FontAwesomeIcon icon={ faExternalLinkAlt } /> </span>
              </a>
              <a href="https://www.bmsscience.com/pipeline-trials/?s=&filter_location=&filter_phase=&filter_status=&filter_specialty=.hematology&search_type=&orderby=relevance&is_parent_ta=YES&filter_celgene_da=" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>

            <div className="investigation-pathways-our-pipeline-wrapper">
              <a href="https://www.bolderscience.com/bmspipeline/solidtumor-hematology/event-2/" target="_blank" rel="noreferrer">Our Pipeline<FontAwesomeIcon icon={ faExternalLinkAlt } /></a>
            </div>

            <div className="investigation-pathways-investigation-pathways-wrapper">
              <span>Recruiting Clinical Trials</span>
              <Link to="/hematology/our-research/recruiting-clinical-trials" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></Link>
            </div>

            <div className="medical-information-wrapper">
              <span>Medical Information</span>
              <a href="http://www.globalbmsmedinfo.com" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>
          </div>
        </div>
      </section>

      <div className="hematology-our-research-footer">
        <p className="copyright-info">&copy;2021 Bristol-Myers Squibb. All rights reserved. HE-US-2100792 10/21</p>

        <Link to="/" className="button-pink back-to-home"><img src={ process.env.PUBLIC_URL + "/img/icons/arrow-back.svg" } alt="Arrow Back" /> Back to Home Page</Link>
        <a href="https://www.bms.com/privacy-policy.html" target="_blank" rel="noreferrer" className="button-dark privacy-policy-btn">Privacy Policy</a>
      </div>

      { !!modalName &&
        <Modal
          show={ !!modalName }
          onHide={ () => setModalName(modalName => [ "cereblon-modulation-imid-pi", "cereblon-modulation-imid-mab" ].includes(modalName) ? "cereblon-modulation" : "") }
          backdrop="static"
          centered
          className="hematology-our-research-modal-wrapper"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            
            { modalName === "investigational-targets" &&
              <div className="general-section-wrapper">
                <h2 className="hematology-section-name">Investigational Targets</h2>
                <hr/>
                <ul className="hematology-modal-links">
                  <h3 className="investigational-targets-subsection-h3">Protein Degradation</h3>
                  <li key="GSPT1"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100798.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">GSPT1<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                  <li key="Aiolos/Ikaros"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100793.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">Aiolos/Ikaros<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                  <h3 className="investigational-targets-subsection-h3">Phagocytosis</h3>
                  <li key="SIRPa"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100800.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">SIRPa<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                  <h3 className="investigational-targets-subsection-h3">Engineered Tumor Antigen-Specific T Cells</h3>
                  <li key="BCMA"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100794.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">BCMA<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                  <li key="CD19"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100796.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">CD19<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                  <li key="GPRC5D"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100797.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">GPRC5D<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>

                  <h3 className="investigational-targets-subsection-h3">Epigenetic Drivers of Oncogenesis</h3>
                  <li key="LSD1"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100799.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">LSD1<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                  <li key="BET"><a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100795.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer">BET<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>

                </ul>
              </div>
            }
          </Modal.Body>
        </Modal>
      }

    </div>
  );
};

export default React.memo(InvestigationPathways);
