import React, { Fragment } from "react";
import { Button, Container } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import { useModal } from "./UI/ContactUsModal";
import { useIsMobile } from "../utils/hooks";

const TheaterSchedule = () =>
{
  const { setIsContactDialogOpen } = useModal();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      <Header />

      <section className="banner-theater-schedule-wrapper" style={{ backgroundImage: "url(/img/banners/banner-theater-schedule" + (isMobile ? "-mobile" : "") + ".png)" }}>
        <Container>
          <h1>ASH 2021 Product Theater Schedule</h1>
        </Container>
      </section>

      <section className="product-theater-schedule-cards">
              <Container>
                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Mecide Gharbio, MD, BMS, Vice President, US Medical Affairs Hematology</p>
                    <p className="event-name">A Treatment Option for Adults With Relapsed or Refractory (R/R) Large B-Cell Lymphoma (LBCL) After At Least Two Prior Lines of Systemic Therapy</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Saturday, December 11th, 2021<br />11:30am – 12:30pm ET</p>
                  </div>
                </div>

                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Christina Hughes, PhD, BMS, Director, WW Medical Affairs, Hematology Myeloid</p>
                    <p className="event-name">A Treatment Option for Patients with Myelodysplastic Syndromes</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Sunday, December 12th, 2021<br />8:00am – 9:00am ET</p>
                    <a href="https://annualmeeting.hematology.org/sessions/23144868/Product-Theater-A-Treatment-Option-for-Patients-with-Myelodysplastic-Syndromes" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>
                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Ali McBride, BMS, Director, Hematology HEOR</p>
                    <p className="event-name">A Treatment Option for Patients with Acute Myeloid Leukemia</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Monday, December 13th, 2021<br />8:00am – 9:00am ET</p>
                    <a href="https://annualmeeting.hematology.org/sessions/23144867/Product-Theater-A-Treatment-Option-for-Patients-with-Acute-Myeloid-Leukemia" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>
              </Container>
            </section>

      { !isMobile &&
        <section className="smiling-guy-wrapper" style={{ backgroundImage: "url(/img/banners/banner-smiling-guy.png)" }}>
          <Container>
            <div className="content-wrapper">
              <h2>Looking for something specific?</h2>
              <Button className="button-pink" onClick={ () => setIsContactDialogOpen(true) }>Contact Us</Button>
            </div>
          </Container>
        </section>
      }
      { isMobile &&
        <section className="smiling-guy-wrapper" style={{ backgroundImage: "url(/img/banners/banner-smiling-guy-mobile.png)" }}>
          <Container>
            <div className="content-wrapper">
              <h2>Looking for something specific?</h2>
              <Button className="button-pink" onClick={ () => setIsContactDialogOpen(true) }>Contact Us</Button>
            </div>
          </Container>
        </section>
      }
      <Footer />
    </Fragment>
  );
}

export default React.memo(TheaterSchedule);
