import React, { useState }  from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useIsMobile } from "../../../utils/hooks";
import { Modal } from "react-bootstrap";
import OurResearchMobile from "../OurResearchMobile";


export const RCTlinksData = {
  lymphoma: {
    header: "Lymphoma",
    name: "Lymphoma",
    links: {
      "Iberdomide in B-Cell Lymphoma ": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/lymphoma/HE-US-2100779.pdf?#view=Fit&toolbar=0",
      "Iberdomide in R/R Lymphomas": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/lymphoma/HE-US-2100778.pdf?#view=Fit&toolbar=0",
      "CC-99282 + Obinutuzumab in CLL and SLL": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/lymphoma/HE-US-2100784.pdf?#view=Fit&toolbar=0",
      "CC-99282 +/- Rituxumab in R/R NHL": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/lymphoma/HE-US-2100785.pdf?#view=Fit&toolbar=0"
    },
  },
  myelioid_anemia: {
    header: "Myeloid Diseases and Anemia",
    name: "Myeloid Diseases",
    links: {
      "CC-90011 in R/R AML": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100788.pdf?#view=Fit&toolbar=0",
      "Oral Azacitidine in AML": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100789.pdf?#view=Fit&toolbar=0",
      "Luspatercept in MPN-MF": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100781.pdf?#view=Fit&toolbar=0",
      "Luspatercept in MDS": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100780.pdf?#view=Fit&toolbar=0",
      "Fedratinib Myelofibrosis": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100783.pdf?#view=Fit&toolbar=0",
      "BMS-986158 in Myelofibrosis": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100786.pdf?#view=Fit&toolbar=0",
    },
    name2: "Anemia",
    links2: {
      "Luspatercept in β-Thalassemia" : "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/myeloid/HE-US-2100790.pdf?#view=Fit&toolbar=0",
    },
  },
  multipleMyeloma: {
    header: "Multiple Myeloma",
    name: "Multiple Myeloma",
    links: {
      "KarMMa-2: Ide-cel in R/R MM": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100774.pdf?#view=Fit&toolbar=0",
      "KarMMa-3: Ide-cel in R/R MM": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100775.pdf?#view=Fit&toolbar=0",
      "KarMMa-4: Ide-cel in Newly Diagnosed MM ": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100776.pdf?#view=Fit&toolbar=0",
      "KarMMa-7: Ide-cel in R/R MM": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100777.pdf?#view=Fit&toolbar=0",
      "Iberdomide in MM": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100787.pdf?#view=Fit&toolbar=0",
      "CC-92480 in R/R MM": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100868.pdf?#view=Fit&toolbar=0",
      "CC-92480 in R/R and Newly Diagnosed MM": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/mm/HE-US-2100869.pdf?#view=Fit&toolbar=0",
    },
  },
  racialDiversity: {
    header: "Racial Diversity Considerations",
    name: "Racial Diversity Considerations",
    links: {
      "Diversity in MM Clinical Trials Patient Brochure": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/diversity/HE-US-2100663.pdf?#view=Fit&toolbar=0",
      "Stem Cell Transplants and Race Patient Brochure": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/trials/diversity/HE-US-2100862.pdf?#view=Fit&toolbar=0",
    },
  }
};

const RecruitingClinicalTrials = () =>
{
  const [ modalName, setModalName ] = useState("");
  const isMobile = useIsMobile();

  if (isMobile) {
    return <OurResearchMobile />;
  }

  return (
    <div className={"hematology-our-research-wrapper" + (!!modalName ? " blurry-background" : "") }  style={{ backgroundImage: "url(/img/hematology/research/background-hematology-trials-q4-2021.png)" }}>
      <div className="hematology-our-research-header">
        <Link to="/" className="logo"><img src={ process.env.PUBLIC_URL + "/img/logo.png" } alt="BMS logo" /></Link>
      </div>

      <section className="main-area">
        <div className="main-area-wrapper">
          <div className="main-area-wrapper-inner hematology-trials-main-area-wrapper">
            <h1>Our Hematology Trials</h1>
            <div className="hematology-trials-lymphoma link-wrapper" onClick={ () => setModalName("lymphoma") }>
              <div>
                <h3 >Lymphoma</h3>
              </div>
            </div>
            <div className="hematology-trials-myeloid-disease link-wrapper" onClick={ () => setModalName("myelioid_anemia") }>
              <div>
                <h3 >Myeloid Diseases and Anemia</h3>
              </div>
            </div>
            <div className="hematology-trials-multiple-myeloma link-wrapper" onClick={ () => setModalName("multipleMyeloma") }>
              <div>
                <h3 >Multiple Myeloma</h3>
              </div>
            </div>

            <div className="hematology-trials-racial-diversity-wrapper" onClick={ () => setModalName("racialDiversity") }>
              <span>Racial Diversity Considerations<FontAwesomeIcon icon={ faExternalLinkAlt } /></span>
            </div>
            {/* <Link to={ process.env.PUBLIC_URL + "/pdf/VV-MED-01825_ASCO 2021 Freedom 2 Panel_R06_CF.pdf?#view=Fit&toolbar=0" } target="_blank" rel="noreferrer" className="downloadable-panel fedratinib-in-mf">Fedratinib in MF<br /><img src={ process.env.PUBLIC_URL + "/img/icons/icon-download.svg" } alt="Download" /></Link>
            <Link to={ process.env.PUBLIC_URL + "/pdf/VV-MED-01738_ASCO 2021 Commands Panel_R08_CF.pdf?#view=Fit&toolbar=0" } target="_blank" rel="noreferrer" className="downloadable-panel luspatercept-in-mds">Luspatercept in MDS<br /><img src={ process.env.PUBLIC_URL + "/img/icons/icon-download.svg" } alt="Download" /></Link>
            <Link to={ process.env.PUBLIC_URL + "/pdf/VV-MED-01746_ASCO 2021_Independence_Panel_R09.pdf?#view=Fit&toolbar=0" } target="_blank" rel="noreferrer" className="downloadable-panel luspatercept-in-mf">Luspatercept in MF<br /><img src={ process.env.PUBLIC_URL + "/img/icons/icon-download.svg" } alt="Download" /></Link>
            <Link to={ process.env.PUBLIC_URL + "/pdf/VV-MED-01749_ASCO_2021_Karmma_Panel_R08.pdf?#view=Fit&toolbar=0" } target="_blank" rel="noreferrer" className="downloadable-panel ide-cel-in-mm">Ide-cel in MM<br /><img src={ process.env.PUBLIC_URL + "/img/icons/icon-download.svg" } alt="Download" /></Link> */}

            <div className="hematology-trials-explore-other-trials-wrapper">
              <a href="https://www.bmsscience.com/pipeline-trials/?s=&filter_location=&filter_phase=&filter_status=&filter_specialty=.hematology&search_type=&orderby=relevance&is_parent_ta=YES&filter_celgene_da=" target="_blank" rel="noreferrer" className="link-text">
              <span>Explore<br /> Other Trials <br /> <FontAwesomeIcon icon={ faExternalLinkAlt } /> </span>
              </a>
              <a href="https://www.bmsscience.com/pipeline-trials/?s=&filter_location=&filter_phase=&filter_status=&filter_specialty=.hematology&search_type=&orderby=relevance&is_parent_ta=YES&filter_celgene_da=" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>

            <div className="hematology-trials-our-pipeline-wrapper">
              <a href="https://www.bolderscience.com/bmspipeline/solidtumor-hematology/event-2/" target="_blank" rel="noreferrer">Our Pipeline<FontAwesomeIcon icon={ faExternalLinkAlt } /></a>
            </div>

            <div className="hematology-trials-investigation-pathways-wrapper">
              <span>Investigational Pathways</span>
              <Link to="/hematology/our-research/investigation-pathways" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></Link>
            </div>

            <div className="medical-information-wrapper">
              <span>Medical Information</span>
              <a href="http://www.globalbmsmedinfo.com" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>
          </div>
        </div>
      </section>

      <div className="hematology-our-research-footer">
        <p className="copyright-info">&copy;2021 Bristol-Myers Squibb. All rights reserved. HE-US-2100792 10/21</p>

        <Link to="/" className="button-pink back-to-home"><img src={ process.env.PUBLIC_URL + "/img/icons/arrow-back.svg" } alt="Arrow Back" /> Back to Home Page</Link>
        <a href="https://www.bms.com/privacy-policy.html" target="_blank" rel="noreferrer" className="button-dark privacy-policy-btn">Privacy Policy</a>
      </div>

      { modalName &&
      <>
        <Modal
          show={ !!modalName && modalName in RCTlinksData }
          onHide={ () => setModalName("") }
          backdrop="static"
          centered
          className="hematology-educational-resources-modal-wrapper"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {modalName !== "racialDiversity" &&
              <h2 className="hematology-section-name-header">Recruiting Clinical Trials</h2>
            }
            <h2 className="hematology-section-name">{ RCTlinksData[modalName].name }</h2>
            <hr />
            <ul className="hematology-modal-links">
              { Object.keys(RCTlinksData[modalName].links).map(linkName =>
                <li key={ linkName }><a href={ RCTlinksData[modalName].links[linkName] } target="_blank" rel="noreferrer">{ linkName }<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
              )}
            </ul>
            {RCTlinksData[modalName].hasOwnProperty('name2') &&
            <>
              <h2 className="hematology-section-name">{ RCTlinksData[modalName].name2 }</h2>
              <hr />
              <ul className="hematology-modal-links">
                { Object.keys(RCTlinksData[modalName].links2).map(linkName =>
                  <li key={ linkName }><a href={ RCTlinksData[modalName].links2[linkName] } target="_blank" rel="noreferrer">{ linkName }<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.png" } alt="Arrow Forward" /></a></li>
                )}
              </ul>
            </>
          
            }
          </Modal.Body>
        </Modal>
        </>
      }
    </div>
  );
};

export default React.memo(RecruitingClinicalTrials);
