import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useIsMobile } from "../../utils/hooks";
import OurResearchMobile from "./OurResearchMobile";

const OurResearch = () =>
{
  const isMobile = useIsMobile();

  if (isMobile) {
    return <OurResearchMobile />;
  }
  return (
    <div className="hematology-our-research-wrapper"  style={{ backgroundImage: "url(/img/hematology/background-hematology-research-q4-2021.png)" }}>
      <div className="hematology-our-research-header">
        <Link to="/" className="logo"><img src={ process.env.PUBLIC_URL + "/img/logo.png" } alt="BMS logo" /></Link>
      </div>

      <section className="main-area">
        <div className="main-area-wrapper">
          <div className="main-area-wrapper-inner">
            <h1>Our Research in Hematology</h1>

            <div className="rectuiting-clinical-trials-wrapper">
              <h2>Recruiting Clinical Trials</h2>
              <Link to="/hematology/our-research/recruiting-clinical-trials" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></Link>
            </div>

            <div className="investigation-pathways-wrapper">
              <h2>Investigational Pathways</h2>
              <Link to="/hematology/our-research/investigation-pathways" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></Link>
            </div>

            <div className="our-pipeline-wrapper">
              <a href="https://www.bolderscience.com/bmspipeline/solidtumor-hematology/event-2/" target="_blank" rel="noreferrer">Our Pipeline <FontAwesomeIcon icon={ faExternalLinkAlt } /></a>
            </div>

            <div className="start-your-engines-wrapper">
              <span>Start Your (Search) Engines</span>
              <a href="https://www.bmsscience.com/" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>

            <div className="scientific-resources-wrapper">
              <span>Our Compendium of Presentations</span>
              <a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/HE-US-2100648.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>

            <div className="medical-information-wrapper">
              <span>Medical Information</span>
              <a href="https://www.globalbmsmedinfo.com/" target="_blank" rel="noreferrer" className="pink-circle"><FontAwesomeIcon icon={ faPlus } /></a>
            </div>
          </div>
        </div>
      </section>

      <div className="hematology-our-research-footer">
        <p className="copyright-info">&copy;2021 Bristol-Myers Squibb. All rights reserved. HE-US-2100792 10/21</p>

        <Link to="/" className="button-pink back-to-home"><img src={ process.env.PUBLIC_URL + "/img/icons/arrow-back.svg" } alt="Arrow Back" /> Back to Home Page</Link>
        <a href="https://www.bms.com/privacy-policy.html" target="_blank" rel="noreferrer" className="button-dark privacy-policy-btn">Privacy Policy</a>
      </div>
    </div>
  );
};

export default React.memo(OurResearch);
