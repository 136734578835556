import React, { Fragment, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Header from "../Header";
import Footer from "./Footer";
import { Accordion, Card, Container, Col, Row, useAccordionToggle } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linksData } from "./OurEducationalResources";
import { RCTlinksData } from "./research/RecruitingClinicalTrials";
import FooterAlt from "../FooterAlt";

const InvestigationalPathwaysLinkData = {
    investigationalTargets: {
        name: "Investigational Targets",
        subsections: [
            {
                header: "Protein Degradation",
                links: {
                    "GSPT1" : "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100798.pdf?#view=Fit&toolbar=0",
                    "Aiolos/Ikaros" : "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100793.pdf?#view=Fit&toolbar=0",
                }
            },
            {
                header: "Phagocytosis",
                links: {
                    "SIRPa": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100800.pdf?#view=Fit&toolbar=0",
                }
            },
            {
                header: "Engineered Tumor Antigen-Specific T Cells",
                links: {
                    "BCMA": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100794.pdf?#view=Fit&toolbar=0",
                    "CD19": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100796.pdf?#view=Fit&toolbar=0",
                    "GPRC5D": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100797.pdf?#view=Fit&toolbar=0",
                }
            },
            {
                header: "Epigenetic Drivers of Oncogenesis",
                links: {
                    "LSD1": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100799.pdf?#view=Fit&toolbar=0",
                    "BET": "https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/key-targets/HE-US-2100795.pdf?#view=Fit&toolbar=0",
                }
            }
        ]
    }
}
const OurResearchMobile = () =>
{
  const [ activeTab, setActiveTab ] = useState(null);

  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setActiveTab( eventKey === activeTab ? null : eventKey );
    });

    return (
      <Accordion.Toggle as={ Card.Header } onClick={ customOnClick }>
        { children }
        { eventKey === activeTab ? <FontAwesomeIcon icon={ faMinus } /> : <FontAwesomeIcon icon={ faPlus } /> }
      </Accordion.Toggle>
    )
  };

  return (
    <Fragment>
      <Header />

      <section className="mobile-section-name-wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <h2>Our Research in Hematology</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mobile-educational-resources-names-wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <h3>Recruiting Clinical Trials</h3>

              <Accordion className="medicines-accordion">
                { Object.keys(RCTlinksData).map(name =>
                  <Card key={ name }>
                    <CustomToggle eventKey={ name }>{ RCTlinksData[name].header }</CustomToggle>
                    <Accordion.Collapse eventKey={ name }>
                      <Card.Body>
                        <ul className="accordion-links">
                        {RCTlinksData[name].hasOwnProperty('name2') &&
                            <h4>{RCTlinksData[name].name}</h4>
                        }
                                { Object.keys(RCTlinksData[name].links).map(linkName =>
                                    <li key={ linkName }><a href={ RCTlinksData[name].links[linkName] } target="_blank" rel="noreferrer">{ linkName }<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Forward" /></a></li>
                                )}
                            {RCTlinksData[name].hasOwnProperty('name2') &&
                            <>
                                <h4>{RCTlinksData[name].name2}</h4>
                                { Object.keys(RCTlinksData[name].links2).map(linkName =>
                                    <li key={ linkName }><a href={ RCTlinksData[name].links2[linkName] } target="_blank" rel="noreferrer">{ linkName }<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Forward" /></a></li>
                                )}
                            </>
                            }
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
              </Accordion>

            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h3>Investigational Pathways</h3>
              <a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/HE-US-2100801.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer" className="medicine-name">
                Cellular Therapies
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>
              <a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/pathways/HE-US-2100802.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer" className="medicine-name">
                CELMoD Agents
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>
              
              <Accordion className="medicines-accordion">
                { Object.keys(InvestigationalPathwaysLinkData).map(name =>
                  <Card key={ name }>
                    <CustomToggle eventKey={ name }>{ InvestigationalPathwaysLinkData[name].name }</CustomToggle>
                    <Accordion.Collapse eventKey={ name }>
                      <Card.Body>
                        <ul className="accordion-links">
                          { Object.keys(InvestigationalPathwaysLinkData[name].subsections).map(subsection =>
                          <>
                            <h4>{InvestigationalPathwaysLinkData[name].subsections[subsection].header}</h4>
                            {Object.keys(InvestigationalPathwaysLinkData[name].subsections[subsection].links).map(linkName =>
                                <li key={linkName}><a href={ InvestigationalPathwaysLinkData[name].subsections[subsection].links[linkName] } target="_blank" rel="noreferrer">{ linkName }<img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Forward" /></a></li>
                            )}

                          </>
                          )}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )}
              </Accordion>

            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h3>Our Research</h3>

              <a href="https://www.bmsscience.com/" target="_blank" rel="noreferrer" className="medicine-name">
                Start Your (Search) Engines
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>

              <a href="https://gc-static-content.s3.amazonaws.com/bms/1954_BMS%20ASH/digital/our-research/HE-US-2100648.pdf?#view=Fit&toolbar=0" target="_blank" rel="noreferrer" className="medicine-name">
                Our Compendium of Presentations
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>

              <a href="https://www.bms.com/researchers-and-partners/in-the-pipeline.html" target="_blank" rel="noreferrer" className="medicine-name">
                Our Pipeline
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>

              <a href="https://www.globalbmsmedinfo.com/" target="_blank" rel="noreferrer" className="medicine-name">
                Medical Information
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>

            </Col>
          </Row>
        </Container>
      </section>

      <section className="mobile-link-section-wrapper" style={{ marginBottom: "3rem" }}>
        <Container>
          <Row>
            <Col md={12}>
              <a href="https://www.bms.com/about-us/contact-us.html" target="_blank" rel="noreferrer" className="mobile-link-section-wrapper-inner" style={{ backgroundColor: "#FEDCCA" }}>
                <span>Contact Us</span>
                <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-forward.svg" } alt="Arrow Right" />
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer/>
    </Fragment>
  );
};

export default React.memo(OurResearchMobile);
